import A from "@/assets/img/icons/groups/A.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Power from "@/assets/img/icons/power.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";
import A1 from "@/assets/img/icons/groups/A1.svg";
import A2 from "@/assets/img/icons/groups/A2.svg";

import Hornet from "@/assets/img/groups/a/hornet.webp";

import Ride2 from "@/assets/img/groups/a_global/2.webp";
import Ride5 from "@/assets/img/groups/a_global/5.webp";
import Ride7 from "@/assets/img/groups/a_global/7.webp";
import Ride8 from "@/assets/img/groups/a_global/8.webp";
import Moto1 from "@/assets/img/groups/a_global/moto.webp";
import Moto2 from "@/assets/img/groups/a_global/skupina.webp";

export default {
    title: "Motocykl",
    subtitle: "od 20 let",
    image: A,
    route: 'a',
    pageTitle: "Skupina A",
    headingImage: Hornet,
    whatCanIDrive: [
        "Motocykly bez rozdílu výkonu, tříkolová motorová vozidla nad 15 kW, čtyřkolová vozidla do 15 kW a hmotnosti max 400 kg v nenaloženém stavu"
    ],
    contact: [
        {
            "name": "František Fišer ml.",
            "phone": "+420 603 449 813",
        }
    ],
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 18 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "24 let, nebo 2 roky držení A2"
        },
        {
            icon: Power,
            title: "Maximální výkon",
            body: "Neomezený"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "Bez předchozích zkušeností: 9 hodin teorie a 18 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 24 let, nebo 2 roky držení A2'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    extensions: [
        {
            fromGroup: A1,
            length: "6 hodin teorie a 10 hodin praxe",
            age: "18 let",
            price: "Individuální"
        },
        {
            fromGroup: A2,
            length: "Dle délky držení A2",
            age: "od 20 let",
            price: "Individuální"
        }
    ],
    gallery: [
        {
            image: Hornet,
            alt: ""
        },
        {
            image: Ride2,
            alt: ""
        },
        {
            image: Ride5,
            alt: ""
        },
        {
            image: Ride7,
            alt: ""
        },
        {
            image: Ride8,
            alt: ""
        },
        {
            image: Moto1,
            alt: ""
        },
        {
            image: Moto2,
            alt: ""
        },
    ],
}