import B from "@/assets/img/icons/groups/B.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Weight from "@/assets/img/icons/weight.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";
import Fanda from "@/assets/img/groups/b/fanda.webp";
import Bile from "@/assets/img/groups/b/bile.webp";
import BilePopis from "@/assets/img/groups/b/bile_popis.webp";
import Hnede from "@/assets/img/groups/b/hnede.webp";


export default {
    title: "Osobní Auto",
    subtitle: "od 18 let",
    image: B,
    headingImage: Fanda,
    route: 'b',
    pageTitle: "Skupina B",
    whatCanIDrive: [
        "Motorové vozidlo s výjimkou vozidel skupin A1, A2, A, s největší povolenou hmotnost do 3.500 kg, s nejvýše 8 místy k sezení kromě místa řidiče, ke kterým smí být připojeno přípojné vozidlo: a/ o největší povolené hmotnosti nepřevyšující 750 kg, b/ o největší povolené hmotnosti převyšující 750 kg, pokud největší povolená hmotnost této jízdní soupravy nepřevyšuje 3.500 kg",
        "Vozidla zařazená do skupiny AM",
        "Vozidla zařazená do skupiny A1, pokud jsou vybavena automatickou převodovkou",
        "Tříkolová vozidla zařazená do skupiny A (pouze pro řidiče starší 21 let)",
        "Vozidla zařazená do skupiny B1",
        "Vozidla která jsou určena pro přepravu nákladu a jejichž největší povolená hmotnost převyšuje 3.500 kg ale nepřevyšuje 4.250 kg a jako palivo nebo zdroj energie využívají elektřinu, vodík, biometan, CNG, LPG, LNG (pouze bez přípojného vozidla; pouze pro řidiče, kteří jsou držiteli sk. B nejméně 2 roky; pouze za splnění dalších podmínek dle Zák. 361/2000 Sb. § 81 odst. 4)"
    ],
    contact: [
        {
            "name": "Jana Fišerová",
            "phone": "+420 604 656 862",
        },
        {
            "name": "František Fišer ml.",
            "phone": "+420 603 449 813",
        }
    ],
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 18 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "18 let"
        },
        {
            icon: Weight,
            title: "Hmotnost",
            body: "do 3 500kg"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "11 hodin teorie a 34 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 18 let'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: Fanda,
            alt: ""
        },
        {
            image: Bile,
            alt: ""
        },
        {
            image: BilePopis,
            alt: ""
        },
        {
            image: Hnede,
            alt: ""
        },
    ],
}