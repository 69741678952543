<template>
  <div class="lqd-sticky-placeholder hidden"></div>
  <header id="site-header" class="main-header is-not-stuck" data-sticky-header="true"
          data-sticky-values-measured="false" data-sticky-options='{"disableOnMobile": true}'>
    <div class="lqd-head-sec-wrap border-black-10 px-30 py-0 bg-white md:hidden">
      <div class="w-full flex items-center justify-between">
        <div class="w-20percent flex items-center justify-start">
          <div class="module-logo flex navbar-brand-plain w-185 py-20 pl-15">
            <a class="navbar-brand flex p-0 relative" href="/">
              <span class="navbar-brand-inner">
                <img width="210" class="transition-opacity" src="./../assets/img/logo.svg" alt="Autoškola Fišer">
              </span>
            </a>
          </div>
        </div>
        <div class="w-80percent flex items-center justify-end">
          <div class="flex flex-row items-center justify-end w-full">
            <div class="mr-30 module-primary-nav flex link-black-50 lg:mr-0">
              <div class="navbar-collapse inline-flex p-0 lqd-submenu-default-style"
                   id="main-header-collapse" aria-expanded="false" role="navigation">
                <ul class="main-nav flex reset-ul inline-ul lqd-menu-counter-right lqd-menu-items-inline main-nav-hover-fill"
                    data-submenu-options='{"toggleType": "fade" ,  "handler": "mouse-in-out"}'
                    data-localscroll="true">
                  <li class="text-14 font-medium leading-2em">
                    <a class="hover:text-black" href="/" data-localscroll="true">
                      Domů
                    </a>
                  </li>
                  <li class="text-14 font-medium leading-2em">
                    <a class="hover:text-black" href="/#groups" data-localscroll="true">
                      Naše služby
                    </a>
                  </li>
                  <li class="text-14 font-medium leading-2em">
                    <a class="hover:text-black" href="/#testimonials" data-localscroll="true">
                      Recenze
                    </a>
                  </li>
                  <li class="text-14 font-medium leading-2em">
                    <a class="hover:text-black" href="#site-footer" data-localscroll="true">
                      Kontakty
                    </a>
                  </li>
                  <li class="text-14 font-medium leading-2em">
                    <a class="hover:text-black" href="https://etesty2.mdcr.cz/" target="_blank">
                      E-Testy
                    </a>
                  </li>
                  <li class="text-14 font-medium leading-2em menu-item-has-children">
                    <a class="hover:text-black">
                      Ke stažení
                      <span class="link-icon inline-flex hide-if-empty right-icon">
														<i class="lqd-icn-ess icon-ion-ios-arrow-down"></i>
													</span>
                    </a>
                    <ul class="nav-item-children">
                      <li>
                        <a href="/assets/files/prihlaska_do_vycviku.pdf" target="_blank"><span><img src="@/assets/img/icons/download_black.svg" style="width: 20px" alt="Download"></span> Přihláška do výcviku</a>
                      </li>
                      <li>
                        <a href="/assets/files/lekarsky_posudek.pdf" target="_blank"><span><img src="@/assets/img/icons/download_black.svg" style="width: 20px" alt="Download"></span> Lékařský posudek</a>
                      </li>
                      <li>
                        <a href="/assets/files/zadost_vybodovani.pdf" target="_blank"><span><img src="@/assets/img/icons/download_black.svg" style="width: 20px" alt="Download"></span> Žádost o vrácení ŘO při vybodování</a>
                      </li>
                      <li>
                        <a href="/assets/files/zadost_rp_odebrany_soudem.pdf" target="_blank"><span><img src="@/assets/img/icons/download_black.svg" style="width: 20px" alt="Download"></span> Žádost o vrácení ŘO odebraného soudem</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <a href="assets/files/lekarsky_posudek.pdf" target="_blank"
               class="btn btn-solid btn-hover-txt-switch-change btn-icon-right text-13 font-medium whitespace-nowrap bg-blue-600 text-white rounded-100 shadow-md module-btn-sm lg:hidden mr-10"
               data-localscroll="true">
              <span class="btn-txt">Lékařský posudek</span>
              <span class="btn-icon w-15">
										<img src="./../assets/img/icons/download.svg" alt="Download">
									</span>
            </a>
            <a href="/assets/files/prihlaska_do_vycviku.pdf" target="_blank"
               class="btn btn-solid btn-hover-txt-switch-change btn-icon-right text-13 font-medium whitespace-nowrap bg-blue-700 text-white rounded-100 shadow-md module-btn-sm lg:hidden"
               data-localscroll="true">
              <span class="btn-txt">Přihláška do výcviku</span>
              <span class="btn-icon w-15">
										<img src="./../assets/img/icons/download.svg" alt="Download">
									</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="lqd-mobile-sec relative xxl:hidden d-lg-flex">
      <div class="lqd-mobile-sec-inner navbar-header flex items-stretch w-full">
        <div class="lqd-mobile-modules-container empty"></div>
        <button type="button"
                class="navbar-toggle collapsed nav-trigger style-mobile flex relative items-center justify-end border-none bg-transparent p-0"
                data-ld-toggle="true" data-bs-toggle="collapse" data-bs-target="#lqd-mobile-sec-nav"
                aria-expanded="false"
                data-toggle-options='{ "changeClassnames":  {"html": "mobile-nav-activated"} }'>
          <span class="sr-only">Menu</span>
          <span class="bars inline-block relative z-1">
								<span class="bars-inner flex flex-col w-full h-full">
									<span class="bar inline-block"></span>
									<span class="bar inline-block"></span>
									<span class="bar inline-block"></span>
								</span>
							</span>
        </button>
        <a class="navbar-brand flex relative" href="/">
							<span class="navbar-brand-inner">
								<img class="logo-default" src="./../assets/img/logo.svg" alt="Autoškola Fišer">
							</span>
        </a>
      </div>
      <div class="lqd-mobile-sec-nav w-full absolute z-10">
        <div class="mobile-navbar-collapse navbar-collapse collapse w-full" id="lqd-mobile-sec-nav"
             aria-expanded="false" role="navigation">
          <ul id="mobile-primary-nav" class="reset-ul lqd-mobile-main-nav main-nav nav"
              data-localscroll="true"
              data-localscroll-options='{"itemsSelector":"> li > a", "trackWindowScroll": true, "includeParentAsOffset": true}'>
            <li>
              <a href="/">Domů</a>
            </li>
            <li>
              <a href="/#groups">Naše služby</a>
            </li>
            <li>
              <a href="/#gallery">Galerie</a>
            </li>
            <li>
              <a href="/#testimonials">Recenze</a>
            </li>
            <li>
              <a href="#site-footer">Kontakty</a>
            </li>
            <li>
              <a href="https://etesty2.mdcr.cz/" target="_blank">E-Testy</a>
            </li>
            <li>
              <a href="/assets/files/prihlaska_do_vycviku.pdf" target="_blank"><span><img src="@/assets/img/icons/download.svg" style="width: 20px" alt="Download"></span> Přihláška do výcviku</a>
            </li>
            <li>
              <a href="/assets/files/lekarsky_posudek.pdf" target="_blank"><span><img src="@/assets/img/icons/download.svg" style="width: 20px" alt="Download"></span> Lékařský posudek</a>
            </li>
            <li>
              <a href="/assets/files/zadost_vybodovani.pdf" target="_blank"><span><img src="@/assets/img/icons/download.svg" style="width: 20px" alt="Download"></span> Vrácení ŘO při vybodování</a>
            </li>
            <li>
              <a href="/assets/files/zadost_rp_odebrany_soudem.pdf" target="_blank"><span><img src="@/assets/img/icons/download.svg" style="width: 20px" alt="Download"></span> Vrácení ŘO odebraného soudem</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
}
</script>