import B96 from "@/assets/img/icons/groups/B96.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";

import Karoq from "@/assets/img/groups/b/karoq_prives.webp";
import Prives from "@/assets/img/groups/b/prives.webp";
import TouaregPrives from "@/assets/img/groups/b/touareg_prives.webp";

import Fanda from "@/assets/img/groups/b/fanda.webp";
import Bile from "@/assets/img/groups/b/bile.webp";
import BilePopis from "@/assets/img/groups/b/bile_popis.webp";
import Hnede from "@/assets/img/groups/b/hnede.webp";

export default {
    title: "B96",
    subtitle: "do 4.250kg",
    image: B96,
    route: 'b96',
    headingImage: TouaregPrives,
    pageTitle: "Skupina B96",
    whatCanIDrive: [
        "Jízdní soupravu složenou z motorového vozidla skupiny B a přípojného vozidla o největší povolené hmostnosti převyšující 750 kg, pokud nejvyšší povolená hmotnost této soupravy převyšuje 3.500 kg ale nepřevyšuje 4.250 kg"
    ],
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 5 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "18 let"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "11 hodin teorie a 34 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: 'Řidičské oprávnění skupiny B'
        },
        {
            icon: ID,
            text: 'věk: 18 let'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: Karoq,
            alt: ""
        },
        {
            image: Prives,
            alt: ""
        },
        {
            image: TouaregPrives,
            alt: ""
        },
        {
            image: Fanda,
            alt: ""
        },
        {
            image: Bile,
            alt: ""
        },
        {
            image: BilePopis,
            alt: ""
        },
        {
            image: Hnede,
            alt: ""
        },
    ],
}