<template>
  <div>
    <div class="ld-fancy-heading relative">
      <h5 class="text-14 uppercase tracking-1 text-black ld-fh-element mb-0/5em inline-block relative">
        {{ name }}</h5>
    </div>

    <div class="ld-fancy-heading relative" v-for="(text, index) in subtext" :key="index">
      <span class="text-12 uppercase tracking-1 text-black-60 ld-fh-element mb-0/5em inline-block relative">{{ text }}</span>
    </div>

    <div class="ld-fancy-heading relative">
      <a :href="'tel:' + phone"
         class="text-18 font-medium mb-1/75em text-black-60 ld-fh-element inline-block relative">
        {{ phone }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LectorView",
  props: {
    name: String,
    subtext: {},
    phone: String,
  }
}
</script>