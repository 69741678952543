import KJ from "@/assets/img/icons/groups/KJ.svg";
import AM from "@/assets/img/icons/groups/AM.svg";
import A1 from "@/assets/img/icons/groups/A1.svg";
import A2 from "@/assets/img/icons/groups/A2.svg";
import A from "@/assets/img/icons/groups/A.svg";
import B from "@/assets/img/icons/groups/B.svg";
import BE from "@/assets/img/icons/groups/BE.svg";
import C from "@/assets/img/icons/groups/C.svg";
import CE from "@/assets/img/icons/groups/CE.svg";
import D from "@/assets/img/icons/groups/D.svg";
import DE from "@/assets/img/icons/groups/DE.svg";
import T from "@/assets/img/icons/groups/T.svg";
import Fanda from "@/assets/img/groups/b/fanda.webp";

export default {
    title: "Kondiční jízdy",
    subtitle: "od 800 Kč/hod",
    image: KJ,
    headingImage: Fanda,
    route: 'kondicni-jizdy',
    pageTitle: "Kondiční jízdy",
    prices: false,
    hours: [
        {
            fromGroup: AM,
            price: "800Kč/hod."
        },
        {
            fromGroup: A1,
            price: "800Kč/hod."
        },
        {
            fromGroup: A2,
            price: "800Kč/hod."
        },
        {
            fromGroup: A,
            price: "800Kč/hod."
        },
        {
            fromGroup: B,
            price: "800Kč/hod."
        },
        {
            fromGroup: BE,
            price: "1 200Kč/hod."
        },
        {
            fromGroup: C,
            price: "1 200Kč/hod."
        },
        {
            fromGroup: CE,
            price: "1 200Kč/hod."
        },
        {
            fromGroup: D,
            price: "1 200Kč/hod."
        },
        {
            fromGroup: DE,
            price: "1 200Kč/hod."
        },
        {
            fromGroup: T,
            price: "1 200Kč/hod."
        },
    ],
}