import A1 from "@/assets/img/icons/groups/A1.svg";
import A2 from "@/assets/img/icons/groups/A2.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Power from "@/assets/img/icons/power.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";

import CB from "@/assets/img/groups/a2/cb.webp";

import Ride2 from "@/assets/img/groups/a_global/2.webp";
import Ride4 from "@/assets/img/groups/a_global/4.webp";
import Ride5 from "@/assets/img/groups/a_global/5.webp";
import Ride6 from "@/assets/img/groups/a_global/6.webp";
import Ride7 from "@/assets/img/groups/a_global/7.webp";
import Ride8 from "@/assets/img/groups/a_global/8.webp";
import Moto1 from "@/assets/img/groups/a_global/moto.webp";
import Moto2 from "@/assets/img/groups/a_global/skupina.webp";

export default {
    title: "Motocykl A2",
    subtitle: "od 18 let",
    headingImage: CB,
    image: A2,
    route: 'a2',
    pageTitle: "Skupina A2",
    whatCanIDrive: [
        "Motocykly o výkonu nejvýše 35 kW (poměr výkon/hmotnost max. 0,2 kW/kg) které nebyly upraveny z motocyklu s více než dvojnásobným výkonem"
    ],
    contact: [
        {
            "name": "František Fišer ml.",
            "phone": "+420 603 449 813",
        }
    ],
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 16 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "od 18 let"
        },
        {
            icon: Power,
            title: "Maximální výkon",
            body: "35kw"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "Bez předchozích zkušeností: 9 hodin teorie a 18 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 18 let'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    extensions: [
        {
          fromGroup: A1,
          length: "Dle délky držení A1",
          age: "18 let",
          price: "Individuální"
        },
    ],
    gallery: [
        {
            image: CB,
            alt: ""
        },
        {
            image: Ride2,
            alt: ""
        },
        {
            image: Ride4,
            alt: ""
        },
        {
            image: Ride5,
            alt: ""
        },
        {
            image: Ride6,
            alt: ""
        },
        {
            image: Ride7,
            alt: ""
        },
        {
            image: Ride8,
            alt: ""
        },
        {
            image: Moto1,
            alt: ""
        },
        {
            image: Moto2,
            alt: ""
        },
    ],
}