<template>
  <div id="lqd-contents-wrap">

    <div class="image-background"
         :style="{backgroundImage: `url(${group.headingImage ? group.headingImage : bgImage})` }">
      <div class="dark-bg">
        <h1 class="heading">{{ group.pageTitle }}</h1>
      </div>
    </div>

    <!-- IKONY -->
    <section class="lqd-section icon-box pt-60 bg-center bg-no-repeat pl-30 pr-30" v-if="group.heading">
      <div class="container-fluid">
        <div class="row justify-center">
          <div class="col col-12">
            <div class="module-container flex flex-wrap justify-between mx-auto w-950 md:w-full">
              <div class="lqd-iconbox-scale w-45percent mb-65" :key="index" v-for="(heading, index) in group.heading">
                <div class="iconbox flex flex-grow-1 relative iconbox-side iconbox-square text-start">
                  <div class="iconbox-icon-wrap">
                    <div
                        class="iconbox-icon-container inline-flex relative z-1 w-80 h-80 text-30 rounded-20 items-center justify-center"
                        style="background-color: rgba(232,232,244,0.75)">
                      <img :src="heading.icon" style="width: 50px" :alt="heading.title"/>
                    </div>
                  </div>
                  <div class="contents">
                    <h3 class="lqd-iconbox-heading text-20 mb-0/85em">
                      <span>{{ heading.title }}</span>
                    </h3>
                    <p v-html="heading.body"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- SKOLENI RIDICU VIEW -->
    <section class="lqd-section pb-70 bg-transparent transition-all z-1 relative"
             v-if="group.training">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10" style="margin-bottom: 100px; text-align: left" :key="index"
                   v-for="(extension, index) in group.training">
                <h5 style="width: 100%;text-align: center;margin-top: 20px">{{ extension.subtitle }}</h5>
                <h3 style="width: 100%;text-align: center;margin-top: 20px">{{ extension.title }}</h3>
                <p style="margin-top: 20px">{{ extension.text }}</p>
                <table class="table text-center">
                  <thead>
                  <tr>
                    <th scope="col">Rozsah výcviku</th>
                    <th scope="col">Cena výcviku</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ extension.length }}</td>
                    <td>{{ extension.price }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Co mohu ridit -->
    <section class="lqd-section bg-transparent transition-all z-1 relative" v-if="group.whatCanIDrive">
      <div class="container">
        <div class="w-full flex flex-col items-center ">
          <div class="ld-fancy-heading">
            <h3 class="mb-0/35em ld-fh-element inline-block relative h3">Co mohu řídit?</h3>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <table class="table">
                  <tbody>
                  <tr><td></td></tr>
                  <tr v-for="(drive, index) in group.whatCanIDrive" :key="index">
                    <th scope="row" style="font-weight: 500">{{drive}}</th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- PODMINKY -->
    <section class="lqd-section pb-70 pt-70 bg-transparent transition-all z-1 relative" v-if="group.conditions">
      <div class="container">
        <div class="w-full flex flex-col items-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Podmínky pro přijetí</h2>
          </div>
          <div class="relative w-full">
            <div class="w-100percent flex flex-col gap-12 my-10 p-10 transition-bg sm:w-full animation-element">
              <div class="iconbox flex flex-grow-1 relative iconbox-circle text-left mb-10" :key="index"
                   v-for="(condition, index) in group.conditions">
                <div class="iconbox-icon-wrap mr-15 text-12 w-20 h-20 flex items-center justify-center">
                  <div class="iconbox-icon-container inline-flex relative z-1">
                    <img :src="condition.icon" alt="icon" style="width: 25px;">
                  </div>
                </div>
                <div class="lqd-iconbox-heading text-15 font-medium -mt-0/1em text-black" v-html="condition.text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- INFORMACE K VYCIVKU L17 -->
    <section class="lqd-section pb-130 bg-transparent transition-all z-1 relative" v-if="group.information">
      <div class="container">
        <div class="w-full flex flex-col items-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Informace k výcviku</h2>
          </div>
          <div class="relative w-full">
            <div class="w-100percent flex flex-col gap-12 my-10 p-10 transition-bg sm:w-full animation-element">
              <div class="iconbox flex flex-grow-1 relative iconbox-circle text-left mb-10" :key="index"
                   v-for="(information, index) in group.information">
                <div class="iconbox-icon-wrap mr-15 text-12 w-20 h-20 flex items-center justify-center">
                  <div class="iconbox-icon-container inline-flex relative z-1">
                    <i class="fa-solid fa-circle-info"></i>
                  </div>
                </div>
                <div class="lqd-iconbox-heading text-15 font-medium -mt-0/1em text-black">{{ information.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- KONDICNI JIZDY VIEW -->
    <section class="lqd-section pb-70 bg-transparent transition-all z-1 relative"
             style="padding-top: 50px;"
             v-if="group.hours">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Ceník</h2>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col">Skupina</th>
                    <th scope="col">Hodinová sazba</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr :key="index" v-for="(extension, index) in group.hours">
                    <th scope="row">
                      <img :src="extension.fromGroup" alt="group" style="width: 40px"/></th>
                    <td>{{ extension.price }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Gallery -->
    <section class="lqd-section pb-80 bg-transparent transition-all z-1 relative"
             v-if="group.gallery">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">S čím budete jezdit</h2>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <GalleryView :images="group.gallery"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- POPLATKY -->
    <section class="lqd-section pb-70 bg-transparent transition-all z-1 relative"
             v-if="group.prices ?? true">
      <div class="container">
        <div class="w-full flex flex-col items-center ">
          <div class="ld-fancy-heading">
            <h3 class="mb-0/35em ld-fh-element inline-block relative h3">Poplatky</h3>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <table class="table">
                  <tbody>
                  <tr>
                    <th scope="row"><b>ZKOUŠKY</b> <br>platba úřadu</th>
                    <td>700 Kč</td>
                  </tr>

                  <tr>
                    <th scope="row"><b>Opakovaná zkouška – testy</b> <br>platba úřadu</th>
                    <td>100 Kč</td>
                  </tr>

                  <tr>
                    <th scope="row"><b>Opakovaná zkouška – jízdy</b> <br>platba úřadu</th>
                    <td>400 Kč</td>
                  </tr>

                  <tr>
                    <th scope="row"><b>Jízda navíc – před opakovanou zkouškou</b></th>
                    <td>500 Kč</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ROZŠÍŘENÍ -->
    <section class="lqd-section pb-70 bg-transparent transition-all z-1 relative"
             style="padding-top: 50px;"
             v-if="group.extensions">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Rozšíření</h2>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col">Ze skupiny</th>
                    <th scope="col">Rozsah výcviku</th>
                    <th scope="col" v-if="group.displayAge ?? true">Věk</th>
                    <th scope="col">Cena rozšíření</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr :key="index" v-for="(extension, index) in group.extensions">
                    <th scope="row">
                      <img :src="extension.fromGroup" alt="group" style="width: 40px"/></th>
                    <td>{{ extension.length }}</td>
                    <td v-if="group.displayAge ?? true">{{ extension.age }}</td>
                    <td>{{ extension.price }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Tlacitka -->
    <section v-if="group.prices ?? true" class="text-center mt-20 mb-20">
      <button type="button" class="btn btn-primary font-bold m-10" @click="openModal1">Vrácení ŘP</button>
      <button type="button" class="btn btn-primary font-bold m-10" @click="openModal2">Jak probíhají závěrečné
        zkoušky?
      </button>
      <button type="button" class="btn btn-primary font-bold m-10" @click="openModal3">Jak probíhá výcvik?</button>
    </section>

    <!-- Jak se přihlásit -->
    <section class="lqd-section pb-70 pt-30 bg-transparent transition-all z-1 relative"
             v-if="group.prices ?? true">
      <div class="container">
        <div class="w-full flex flex-col items-center">
          <div class="ld-fancy-heading">
            <h3 class="mb-0/35em ld-fh-element inline-block relative h3">Jak se přihlásit?</h3>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <span class="mb-10 text-20 font-bold">Chcete mít řidičský průkaz hned, jak to bude možné? Přihlaste se cca 3-4 měsíce před dovršením požadovaného věku a zkoušku můžete dělat hned po narozeninách. </span>
              <div class="row -mr-10 mt-20 -ml-10">
                <ul>
                  <li>Podat písemnou Žádost o přijetí k výuce a výcviku</li>
                  <li>Podat Lékařský posudek o zdravotní způsobilosti k řízení motorových vozidel (nesmí být starší než
                    3 měsíce)
                  </li>
                  <li>Splnit věkový limit pro příslušnou skupinu řidičských oprávnění (výuku a výcvik lze začít 18
                    měsíců před dovršením věku)
                  </li>
                  <li>Mít na území České republiky obvyklé bydliště nebo zde alespoň 6 měsíců studovat</li>
                  <li>Nemít uložený zákaz řízení motorových vozidel atp. (čestné prohlášení je součástí žádosti)</li>
                </ul>
              </div>

              <span class="mb-10 text-15 font-bold">Pokud splňujete všechny podmínky a máte vyplněnou žádost a lékařský posudek, zavolejte nám a domluvíme se na předání.</span>

              <div class="text-center mt-20">
                <LectorView name="Jana Fišerová" phone="+420 604 656 862" v-if="group.contact === undefined"/>
                <LectorView :name="contact.name" :phone="contact.phone" :key="index" v-for="(contact, index) in group.contact"/>
                <h4 class="mt-10">Těšíme se na vás!</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ZMĚNA VYHRAZENA -->
    <section class="lqd-section pb-10 bg-transparent transition-all z-1 relative"
             style="background-image: linear-gradient(0deg, #FAF9FE 0%, #fff 100%)">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="ld-fancy-heading">
            <span class="mb-0/35em ld-fh-element inline-block relative font-bold"
                  v-if="group.hours">ZMĚNA VYHRAZENA.</span>
            <span class="mb-0/35em ld-fh-element inline-block relative font-bold" v-else>ZMĚNA VYHRAZENA. Cena je splatná v den konání zkoušek.</span>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- Vrácení ŘP -->
  <CustomModal :is-visible="isVisible1" @close="closeModal1">
    <section class="lqd-section bg-transparent transition-all z-1 relative"
             v-if="group.prices ?? true">
      <div class="container">
        <div class="w-full flex flex-col items-center ">
          <div class="ld-fancy-heading">
            <h3 class="mb-0/35em ld-fh-element inline-block relative h3">VRÁCENÍ ŘP</h3>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-20">
              <h6 class="mb-0/35em ld-fh-element relative h5">Co potřebuji?</h6>
              <div class="row -mr-10 mt-20 -ml-10">
                <ul class="font-bold">
                  <li>ŽÁDOST O PŘIJETÍ K VÝUCE A VÝCVIKU</li>
                  <li>POSUDEK O ZDRAVOTNÍ ZPŮSOBILOSTI (NESMÍ BÝT STARŠÍ NEŽ 3 MĚSÍCE)</li>
                  <li>AKTUÁLNÍ VÝPIS Z KARTY ŘIDIČE</li>
                  <li>DOPRAVNĚ PSYCHOLOGICKÉ VYŠETŘENÍ</li>
                </ul>
              </div>

              <span>I když to není zákonem předepsáno, doporučujeme všem žadatelům o přezkoušení absolvovat alespoň jednu hodinu praktického výcviku v řízení vozidla. (dle potřeby i více)</span>

            </div>
          </div>

          <div class="relative w-full flex mt-20">
            <div class="container-fluid">
              <h6 class="mb-0/35em ld-fh-element relative h5">Cena?</h6>
              <div class="mt-10">
                <span>Cena za přezkoušení je závislá na množství skupin, které požadujete vrátit. Řešíme ji tedy s každým individuálně. </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </CustomModal>
  <!-- Závěrečné zkoušky -->
  <CustomModal :is-visible="isVisible2" @close="closeModal2">
    <section class="lqd-section bg-transparent transition-all z-1 relative">
      <div class="container">
        <div class="w-full flex flex-col items-center">
          <div class="ld-fancy-heading">
            <h3 class="mb-0/35em ld-fh-element inline-block relative h3">Závěrečné zkoušky</h3>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <span class="mb-10">Po ukončení výuky a výcviku musíte splnit závěrečnou zkoušku, která probíhá zpravidla v jeden den a skládá se z těchto částí:</span>
                <ul>
                  <li>TEST - 25 otázek (minimum 43 bodů z 50)</li>
                  <li>ZKOUŠKA ZE ZNALOSTÍ OVLÁDÁNÍ A ÚDRŽBY VOZIDLA (pouze skupiny C, C+E, D, D+E)</li>
                  <li>ZKOUŠKA Z PRAKTICKÉ JÍZDY S VÝCVIKOVÝM VOZIDLEM</li>
                </ul>
              </div>

            </div>
          </div>

          <div class="relative w-full flex">
            <div class="container-fluid">
              <div class="row -mr-10 -ml-10">
                <h6 class="mb-0/35em mt-40 ld-fh-element relative h5">OPAKOVANÁ ZKOUŠKA</h6>
                <span class="mb-10">Koná se po dohodě s učitelem v následujícím možném termínu, na všechny části zkoušky máte 3 pokusy.</span>
                <ul>
                  <li>Po 3 nezdařených pokusech při testech – <b>NOVÁ VÝUKA V CELÉM ROZSAHU</b></li>
                  <li>Po 3 nezdařených pokusech při praktické jízdě – <b>NOVÝ VÝCVIK V CELÉM ROZSAHU</b></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="relative w-full flex">
            <div class="container-fluid">
              <div class="-mr-10 -ml-10">
                <h6 class="mb-0/35em mt-40 ld-fh-element relative h5">DOPLŇOVACÍ ZKOUŠKA</h6>
                <br>
                <span>Jedná se o zkoušku pouze z praktické jízdy, a to v těchto případech:</span>
                <ul class="mt-10">
                  <li>Žádám od A2 (jsem alespoň 2 roky držitelem A1)</li>
                  <li>Žádám o A ( jsem alespoň 2 roky držitelem A2)</li>
                  <li>Žádám o B96 (jsem držitelem B)</li>
                  <li>Žádám o B (jsem držitelem B automat)</li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </CustomModal>
  <!-- Jak probíhá výcvik -->
  <CustomModal :is-visible="isVisible3" @close="closeModal3">
    <section class="lqd-section bg-transparent transition-all z-1 relative">
      <div class="container">
        <div class="w-full flex flex-col items-center">
          <div class="ld-fancy-heading">
            <h3 class="mb-0/35em ld-fh-element inline-block relative h3">JAK PROBÍHÁ VÝCVIK</h3>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <span class="mb-10">Při výcviku v autoškole musí být žák seznámen s řadou úkonů. Ukončení výcviku a přihlášení na zkoušku není možné dřív, než žák tyto úkony patřičně ovládá. Požadované úkony lze rozdělit do následujících oblastí.</span>
              </div>
            </div>
          </div>

          <div class="relative w-full flex">
            <div class="container-fluid">
              <h6 class="mb-0/35em mt-40 ld-fh-element relative h5">Úkony před jízdou</h6>
              <ul>
                <li>Prověřit <b>stav vozidla</b> - namátková kontrola stavu <b>pneumatik</b>, <b>osvětlení</b> vozidla a
                  směrových světel (blinkry), odrazových skel <b>(zrcátka)</b>, zvukového výstražného zařízení
                  (troubení), funkčnosti řízení a <b>brzd</b>, čelního skla,<b>stěračů</b>, provozních <b>kapalin</b>
                  (například motorového oleje, chladící směsi, kapaliny do ostřikovače skla), přístrojové desky a
                  funkčnost sdělovačů (kontrolky)
                </li>
                <li>Přizpůsobit si <b>sedadlo</b> do správné polohy</li>
                <li>Přizpůsobit si <b>zpětná zrcátka a opěrky</b> hlavy</li>
                <li>Zapnout si bezpečnostní <b>pás</b></li>
                <li>Ověřit, že jsou zavřeny všechny <b>dveře</b></li>
              </ul>
            </div>
          </div>

          <div class="relative w-full flex">
            <div class="container-fluid">
              <h6 class="mb-0/35em mt-40 ld-fh-element relative h5">Jízdní úkony</h6>
              <ul>
                <li><b>Nastartování</b> motoru</li>
                <li>Provedení plynulého rozjetí vozidla, provedení <b>rozjezdu do kopce a z kopce</b>, <b>couvání</b> na
                  přímé silnici nebo couvání doprava či doleva při udržování správného jízdního pruhu
                </li>
                <li><b>Zabrždění vozidla</b> na přesnost zastavení; provedení nouzového zastavení (není povinné)</li>
                <li>Zaparkování vozidla a opuštění parkovacího prostoru (<b>podélné, šikmé a kolmé parkování</b>, na
                  rovině, do kopce a z kopce)
                </li>
                <li><b>Zajetí k obrubníku</b> a vyjetí od něho</li>
                <li><B>Objetí</B> stojícího vozidla a překážky</li>
                <li><b>Otočení vozidla</b> do protisměru</li>
              </ul>
            </div>
          </div>

          <div class="relative w-full flex">
            <div class="container-fluid">
              <h6 class="mb-0/35em mt-40 ld-fh-element relative h5">Úkony během jízdy v provozu</h6>
              <ul>
                <li>Zachování správného směru jízdy</li>
                <li><b>Zrychlení vozidla</b> až na vhodnou rychlost a udržování této rychlosti i během řazení
                  jednotlivých rychlostních stupňů
                </li>
                <li>Vjetí do <b>křižovatky</b> a její bezpečné projetí</li>
                <li>Přizpůsobení rychlosti vozidla při <b>odbočování vlevo a vpravo</b>, a pokud možno v omezeném
                  prostoru
                </li>
                <li><b>Přizpůsobení rychlosti</b> vozidla podmínkám provozu na pozemních komunikacích</li>
                <li><b>Zařazení se</b> do jiného jízdního pruhu</li>
                <li><b>Míjení</b> jedoucího vozidla, zejména v zúžených prostorech</li>
                <li>Dodržování správné <b>vzdálenosti mezi vozidly</b> v jednotlivých jízdních pruzích</li>
                <li><b>Předjíždění</b> v různých situacích</li>
                <li>Odbočení na křižovatce vlevo i vpravo, průjezd křižovatkami a dálničními uzly, odbočení mimo pozemní
                  komunikaci
                </li>
                <li>Znalost jízdy na <b>kruhovém objezdu</b></li>
                <li>Přejíždění železničního úrovňového <b>přejezdu</b></li>
                <li>Jízdy podél <b>zastávek</b> tramvají, autobusů a trolejbusů</li>
                <li>Přejíždění <b>přechodů</b> pro chodce</li>
                <li>Jízdy <b>z kopce a do kopce</b> při velkém podélném sklonu</li>
                <li>Vjezd na <b>dálnici</b> nebo výjezd z dálnice nebo silnice pro motorová vozidla</li>
                <li>Zařazení do pruhu s rychleji jedoucími vozidly</li>
                <li>Přejezd do pruhu s pomaleji jedoucími vozidly</li>
                <li>Jízda v <b>zatáčkách</b></li>
                <li>Provedení nezbytných opatření při <b>vystupování</b> z vozidla</li>
              </ul>
            </div>
          </div>

          <div class="relative w-full flex mb-10">
            <div class="container-fluid pt-35">
              <div class="row">
                <span class="mb-10 text-20 font-bold">Každá vyučovací hodina by měla trvat 45 minut. Teoretická výuka by měla být rozdělena do následujících předmětů s uvedenou hodinovou dotací:</span>
              </div>
            </div>
          </div>

          <div class="relative w-full flex">
            <div class="container-fluid">
              <h6 class="ld-fh-element relative h5">TEORETICKÁ VÝUKA</h6>
              <table class="table">
                <thead>
                <tr>
                  <th>Skupina řidičského oprávnění</th>
                  <th>PPV</th>
                  <th>OÚV</th>
                  <th>TZBJ</th>
                  <th>ZP</th>
                  <th>OP</th>
                  <th>Celkem</th>
                </tr>
                </thead>
                <tr>
                  <td>AM</td>
                  <td>14</td>
                  <td>1</td>
                  <td>6</td>
                  <td>2</td>
                  <td>3</td>
                  <td>26</td>
                </tr>
                <tr>
                  <td>A1</td>
                  <td>14</td>
                  <td>1</td>
                  <td>6</td>
                  <td>2</td>
                  <td>3</td>
                  <td>26</td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>14</td>
                  <td>1</td>
                  <td>6</td>
                  <td>2</td>
                  <td>3</td>
                  <td>26</td>
                </tr>
                <tr>
                  <td>B1</td>
                  <td>16</td>
                  <td>2</td>
                  <td>8</td>
                  <td>2</td>
                  <td>4</td>
                  <td>32</td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>18</td>
                  <td>2</td>
                  <td>10</td>
                  <td>2</td>
                  <td>4</td>
                  <td>36</td>
                </tr>
                <tr>
                  <td>T</td>
                  <td>18</td>
                  <td>10</td>
                  <td>10</td>
                  <td>2</td>
                  <td>4</td>
                  <td>44</td>
                </tr>
              </table>
              <p class="pt-10 font-bold">Vysvětlivky: </p>
              <p><b>PPV</b> Výuka předpisů o provozu vozidel<br><b>OÚV</b> Výuka o ovládání a údržbě
                vozidla<br><b>TZBJ</b> Výuka teorie
                zásad bezpečné jízdy<br><b>ZP</b> Výuka zdravotnické přípravy<br><b>OP</b> Opakování a přezkoušení</p>
            </div>
          </div>

          <div class="relative w-full flex">
            <div class="container-fluid">
              <h6 class="ld-fh-element relative h5">PRAKTICKÝ VÝCIVK</h6>
              <table class="table" style="text-align: center">
                <thead>
                <tr>
                  <th rowspan="3">Skupina řidičského oprávnění</th>
                  <th colspan="4">Praktický výcvik v řízení vozidla</th>
                  <th rowspan="3">PV-ÚV</th>
                  <th rowspan="3">PV-ZP</th>
                  <th rowspan="3">Celkem</th>
                </tr>
                <tr>
                  <th colspan="2">I. etapa</th>
                  <th>II. etapa</th>
                  <th>III. etapa</th>
                </tr>
                <tr>
                  <th>AC</th>
                  <th>MP</th>
                  <th>SP</th>
                  <th>SP</th>
                </tr>
                </thead>
                <tr>
                  <td>AM</td>
                  <td>1</td>
                  <td>2</td>
                  <td>5</td>
                  <td>5</td>
                  <td>1</td>
                  <td>4</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>A1</td>
                  <td>1</td>
                  <td>2</td>
                  <td>5</td>
                  <td>5</td>
                  <td>1</td>
                  <td>4</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>1</td>
                  <td>2</td>
                  <td>5</td>
                  <td>5</td>
                  <td>1</td>
                  <td>4</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>B1</td>
                  <td>2</td>
                  <td>5</td>
                  <td>10</td>
                  <td>6</td>
                  <td>2</td>
                  <td>4</td>
                  <td>29</td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>2</td>
                  <td>5</td>
                  <td>12</td>
                  <td>9</td>
                  <td>2</td>
                  <td>4</td>
                  <td>34</td>
                </tr>
                <tr>
                  <td>T</td>
                  <td>2</td>
                  <td>4</td>
                  <td>8</td>
                  <td>7</td>
                  <td>8</td>
                  <td>4</td>
                  <td>33</td>
                </tr>
              </table>
              <p class="pt-10 font-bold">Vysvětlivky: </p>
              <p><b>AC</b> autocvičiště<br><b>MP</b> minimální provoz<br><b>SP</b> střední provoz<br><b>PV-ÚV</b>
                praktický výcvik údržby vozidla<br><b>PV-ZP</b> praktický výcvik zdravotnické přípravy</p>
            </div>
          </div>


        </div>
      </div>
    </section>
  </CustomModal>
</template>
<script>

import GalleryView from "@/components/Home/GalleryView.vue";
import LectorView from "@/components/Home/LectorView.vue";
import CustomModal from "@/components/Home/Modal.vue";

export default {
  name: 'GroupScreen',
  components: {CustomModal, LectorView, GalleryView},
  computed: {
    group() {
      return this.$store.getters.getGroupByRoute(this.$route.params.route);
    }
  },
  mounted() {
    document.title = this.group.title + ' | Autoškola Fišer';
  },
  data() {
    return {
      bgImage: require('../assets/img/bg.webp'),
      isVisible1: false,
      isVisible2: false,
      isVisible3: false
    }
  },
  methods: {
    openModal1() {
      this.isVisible1 = true;
    },
    closeModal1() {
      this.isVisible1 = false;
    },
    openModal2() {
      this.isVisible2 = true;
    },
    closeModal2() {
      this.isVisible2 = false;
    },
    openModal3() {
      this.isVisible3 = true;
    },
    closeModal3() {
      this.isVisible3 = false;
    }
  }
}

</script>

<style>
.image-background {
  width: 100vw;
  height: 250px;
  background-size: cover;
  background-position: center center;
}

.dark-bg {
  background: rgba(0, 0, 0, 0.64);
  width: 100vw;
  height: 250px;
}

.heading {
  font-size: 60px;
  color: white;
  text-align: center;
  line-height: 250px
}

@media (max-width: 767px) {
  .icon-box .lqd-iconbox-scale {
    margin-bottom: 35px;
    width: 100%;

    &:hover {
      transform: scale(1);
    }
  }
}

.icon-box .lqd-iconbox-scale {
  @media (max-width: 767px) {
    margin-bottom: 35px;
    width: 100%;

  }

  &:hover {
    transform: scale(1);
  }

}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background: #1e20f1;
  color: white;
}

.no-scroll {
  overflow: hidden;
}
</style>