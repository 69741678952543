import CE from "@/assets/img/icons/groups/CE.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Weight from "@/assets/img/icons/weight.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";

import Prives from "@/assets/img/groups/c/prives.webp";
import Zepredu from "@/assets/img/groups/c/zepredu.webp";
import Shora from "@/assets/img/groups/c/shora.webp";
import Interier from "@/assets/img/groups/c/interier.webp";

export default {
    title: "Nákladní Auto + Přívěs",
    subtitle: "od 18 let",
    image: CE,
    headingImage: Prives,
    route: 'ce',
    pageTitle: "Skupina C+E",
    whatCanIDrive: [
        "Jízdni soupravu skupiny D+E (pokud je řidič držitelem skupiny D, získá po složení zkoušek na skupinu C+E automaticky také D+E)"
    ],
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 15 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "21 let (18 let za níže uvedených podmínek)",
        },
        {
            icon: Weight,
            title: "Hmotnost",
            body: "Přívěs s hmotností nad 750 kg"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "6 hodin teorie a 12 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 21 let'
        },
        {
            icon: ID,
            text: '<b>Řidičské oprávnění pro skupinu C+E můžete získat v 18 letech:</b><br>' +
                'a) za účelem řízení v rámci vstupního školení na profesní osvědčení (profesní způsobilost),<br>' +
                'b) pokud jste držitelem profesního osvědčení (dnes profesní způsobilost) a podrobil jste se vstupnímu školení v rozšířeném rozsahu 280 hodin'
        },
        {
            icon: Document,
            text: 'odborná způsobilost'
        },
        {
            icon: Doctor,
            text: 'psychologické vyšetření'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: Prives,
            alt: ""
        },
        {
            image: Zepredu,
            alt: ""
        },
        {
            image: Shora,
            alt: ""
        },
        {
            image: Interier,
            alt: ""
        },
    ],
}