import C from "@/assets/img/icons/groups/C.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Weight from "@/assets/img/icons/weight.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";

import Shora from "@/assets/img/groups/c/shora.webp";
import Zepredu from "@/assets/img/groups/c/zepredu.webp";
import Interier from "@/assets/img/groups/c/interier.webp";

export default {
    title: "Nákladní Auto",
    subtitle: "od 18 let",
    image: C,
    route: 'c',
    headingImage: Shora,
    pageTitle: "Skupina C",
    whatCanIDrive: [
        "Motorová vozidla s výjimkou traktorů, jejichž největší povolená hmotnost převyšuje 3.500 kg s nejvýše 8 místy k sezení kromě místa řidiče, ke kterým smí být připojeno přípojné vozidlo o největší povolené hmotnosti nepřevyšující 750 kg",
        "Jízdní soupravu složenou z motorového vozidla skupiny C a přípojného vozidla o největší povolené hmotnosti vyšší než 750 kg",
        "Jízdní soupravu skupiny B+E",
    ],
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 25 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "21 let (18 let za níže uvedených podmínek)",
        },
        {
            icon: Weight,
            title: "Hmotnost",
            body: "vyšší než 3 500 kg"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "11 hodin teorie a 26 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: '<b>Řidičské oprávnění pro skupinu C můžete získat už v 18 letech, s omezením pouze k řízení vozidel:</b><br>' +
                'a) Ministerstva vnitra používaných policií,<br>' +
                'b) Vězeňské služby České republiky,<br>' +
                'c) ozbrojených sil České republiky,<br>' +
                'd) obecní policie,<br>' +
                'e) Hasičského záchranného sboru České republiky a jednotek požární ochrany,<br>' +
                'f) celních orgánů,<br>' +
                'g) při zkušební jízdě v souvislosti s jejich opravou nebo údržbou<br><br>' +
                'Po dosažení věku 21 let toto omezení pozbývá platnosti.'
        },
        {
            icon: ID,
            text: '<b>Zároveň můžete řidičské oprávnění pro skupinu C získat v 18 letech:</b><br>' +
                'a) za účelem řízení v rámci vstupního školení na profesní osvědčení (profesní způsobilost),<br>' +
                'b) pokud jste držitelem profesního osvědčení (dnes profesní způsobilost) a podrobil jste se vstupnímu školení v rozšířeném rozsahu 280 hodin'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Document,
            text: 'odborná způsobilost'
        },
        {
            icon: Doctor,
            text: 'psychologické vyšetření'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: Zepredu,
            alt: ""
        },
        {
            image: Shora,
            alt: ""
        },
        {
            image: Interier,
            alt: ""
        },
    ],
}